/* eslint-disable string-to-lingui/missing-lingui-transformation */
import {Box, Inline, Skeleton, Text} from '@pleo-io/telescope'

import {ViewDetails} from './pricing-results'

export const PricingResultsSkeleton = () => (
    <>
        <Skeleton loading borderRadius={4}>
            <Box mb={12} />
        </Skeleton>
        <Skeleton loading borderRadius={4}>
            <Box mb={12} />
        </Skeleton>
        <Box pb={28} />
        <Skeleton loading borderRadius={4}>
            <Box mb={12} />
        </Skeleton>
        <Skeleton loading borderRadius={4}>
            <Box mb={12} />
        </Skeleton>
        <Inline mt={48} space={24} alignItems="center">
            <ViewDetails>
                <Skeleton loading borderRadius={4}>
                    <Text variant="large-accent">Contract value</Text>
                </Skeleton>
                <Skeleton loading borderRadius={4}>
                    <Text>Monthly: 280 DKK</Text>
                </Skeleton>
                <Skeleton loading borderRadius={4}>
                    <Text>Annually: 1.280 DKK</Text>
                </Skeleton>
            </ViewDetails>
            <ViewDetails>
                <Skeleton loading borderRadius={4}>
                    <Text as="h4" variant="large-accent">
                        Price per user
                    </Text>
                </Skeleton>
                <Skeleton loading borderRadius={4}>
                    <Text>Monthly: 56 DKK</Text>
                </Skeleton>
                <Skeleton loading borderRadius={4}>
                    <Text>Annually: 102 DKK</Text>
                </Skeleton>
            </ViewDetails>
        </Inline>
    </>
)
