import {spendActivationSetup} from '@product-web/analytics'

type ActionType = 'viewed' | 'clicked' | 'completed'

type AnnouncementCTAs = 'start_setup' | 'remind_self_admin'
export type AnnouncementSteps = 'manage-spend' | 'money-aside' | 'vendor-cards' | 'setup'

type SetupSteps = 'create-vendor-card' | 'create-sub-wallet' | 'activate-vendor-card'
type SetupCTAs =
    | 'create_card'
    | 'sub_wallet'
    | 'remind_employee'
    | 'complete_setup'
    | 'cancel_setup'
    | 'mark_as_done'

type GuideBoxSteps = 'open_setup_page'

interface BaseProperties {
    action?: ActionType
    admin_id?: string
    company_id?: string
    org_id?: string
}

interface AnnouncementComponentProps extends BaseProperties {
    component: 'announcement'
    step?: AnnouncementSteps
    call_to_action?: AnnouncementCTAs
}

interface SetupComponentProps extends BaseProperties {
    component: 'setup'
    step?: SetupSteps
    call_to_action?: SetupCTAs
    employee_id_emailed?: string
}

interface GuideBoxComponentProps extends BaseProperties {
    component: 'guide-box'
    step?: GuideBoxSteps
}

type SpendActivationSetupProperties =
    | AnnouncementComponentProps
    | GuideBoxComponentProps
    | SetupComponentProps

export const trackSpendActivationEvent = (properties: SpendActivationSetupProperties) => {
    spendActivationSetup(properties)
}

export const startSpendActivationSetup = (
    props?: Omit<AnnouncementComponentProps, 'component' | 'action'>,
) => {
    spendActivationSetup({
        component: 'announcement',
        action: 'clicked',
        ...props,
    })
}

export const completeSpendActivationSetup = (
    props?: Omit<SetupComponentProps, 'component' | 'action'>,
) => {
    spendActivationSetup({
        component: 'setup',
        action: 'clicked',
        call_to_action: 'complete_setup',
        ...props,
    })
}

export const trackSpendActivationSetupCancel = (
    props?: Omit<SetupComponentProps, 'component' | 'action' | 'call_to_action'>,
) =>
    spendActivationSetup({
        component: 'setup',
        action: 'clicked',
        call_to_action: 'cancel_setup',
        ...props,
    })
