import {t, Trans} from '@lingui/macro'
import type {ReactNode} from 'react'

import {Box, Card, Inline, NakedButton, Stack, Text, tokens, Tooltip} from '@pleo-io/telescope'
import {Check, Close, Help} from '@pleo-io/telescope-icons'
import {Headset} from '@pleo-io/telescope-pictograms'

import {PlanTypeName} from '@product-web/api-types/billing'
import type {BillingInfoCurrency, CpqRatePlanType} from '@product-web/bff-moons/generated/beyond'
import {SupportedLanguage} from '@product-web/i18n'
import {breakpoints} from '@product-web/styles/theme'
import {useUser} from '@product-web/user'
import {exhaustiveCheck} from '@product-web/utils'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'
import {ContactSupport} from '@product-web-features/ui-contact-support'

import {formatMinorNumberToCurrency} from '../../lib/currency-formatter'
import {
    getArrayDifference,
    getPlanContentConfiguration,
    renderUpToCashbackLine,
    renderUpToCreditLine,
    renderUpToCreditPrice,
} from '../pricing-plans.helper'

type EligibleEntitlements = 'CASHBACK' | 'CREDIT_LINE'
type EligibleEntitlementsLimits = {
    cashback: number
    creditLine: string
}

type PlanChangeSummaryProps = {
    targetPlan: {planType: CpqRatePlanType; isUpgrade: boolean}
    currentPlan: CpqRatePlanType
    isLegacyPlan: boolean
    companyCurrency: BillingInfoCurrency
    onChatWithUs: (planName: CpqRatePlanType) => void
    children?: ReactNode
}

export const PlanChangeSummary = ({
    targetPlan,
    currentPlan,
    companyCurrency,
    onChatWithUs,
    children,
    isLegacyPlan,
}: PlanChangeSummaryProps) => {
    const user = useUser()
    const locale = user?.language ?? SupportedLanguage.EN
    const {baseEntitlements, eligibleEntitlements, eligibleEntitlementsLimits} =
        getListOfEntitlements({
            targetPlan,
            currentPlan,
            companyCurrency,
            locale,
            isLegacyPlan,
        })
    const baseEntitlementsLeft = baseEntitlements.slice(0, Math.ceil(baseEntitlements.length / 2))
    const baseEntitlementsRight = baseEntitlements.slice(Math.ceil(baseEntitlements.length / 2))

    const isMobile = useMediaQuery(`(max-width: ${breakpoints.tabletUp})`)
    const isUpgrade = targetPlan.isUpgrade || isLegacyPlan

    return (
        <Stack space={32}>
            <Card padding={32}>
                <Text variant="xlarge-accent" weight="medium">
                    {isUpgrade ? t`By upgrading, you'll get` : t`By downgrading, you'll lose`}
                </Text>
                <Inline paddingTop={16} paddingBottom={24}>
                    {isMobile && (
                        <Stack space={12}>
                            {baseEntitlements.map((entitlement, index) => (
                                <EntitlementLine
                                    key={`${index}-${entitlement}`}
                                    entitlement={entitlement}
                                    isUpgrade={isUpgrade}
                                />
                            ))}
                        </Stack>
                    )}
                    {!isMobile && (
                        <>
                            <Stack space={12}>
                                {baseEntitlementsLeft.map((entitlement, index) => (
                                    <EntitlementLine
                                        key={`${index}-${entitlement}`}
                                        entitlement={entitlement}
                                        isUpgrade={isUpgrade}
                                    />
                                ))}
                            </Stack>
                            <Stack space={12}>
                                {baseEntitlementsRight.map((entitlement, index) => (
                                    <EntitlementLine
                                        key={`${index}-${entitlement}`}
                                        entitlement={entitlement}
                                        isUpgrade={isUpgrade}
                                    />
                                ))}
                            </Stack>
                        </>
                    )}
                </Inline>
                {targetPlan.isUpgrade && !!eligibleEntitlements.size && (
                    <EligibleEntitlementsSection
                        eligibleEntitlements={eligibleEntitlements}
                        eligibleEntitlementsLimits={eligibleEntitlementsLimits}
                        planType={targetPlan.planType}
                    />
                )}
                <PlanChangeHelpSection
                    isMobile={isMobile}
                    onChatWithUs={() => onChatWithUs(targetPlan.planType)}
                />
            </Card>
            {children}
        </Stack>
    )
}

type EligibleEntitlementsSectionProps = {
    eligibleEntitlements: Set<EligibleEntitlements>
    eligibleEntitlementsLimits: EligibleEntitlementsLimits
    planType: CpqRatePlanType
}

const EligibleEntitlementsSection = ({
    eligibleEntitlements,
    eligibleEntitlementsLimits,
    planType,
}: EligibleEntitlementsSectionProps) => {
    const tooltipText = getTooltipText({
        eligibleEntitlements,
        eligibleEntitlementsLimits,
        planType,
    })

    return (
        <Box borderTop={tokens.borderPrimary} pt={20} pb={24}>
            <Stack space={12}>
                <Inline space={6} alignItems="center">
                    <Text variant="large-accent" weight="medium">
                        <Trans>For eligible customers</Trans>
                    </Text>

                    <Tooltip content={tooltipText}>
                        <NakedButton aria-label={t`Feature eligibility explanation`}>
                            <Help size={16} color={tokens.colorContentStaticQuiet} />
                        </NakedButton>
                    </Tooltip>
                </Inline>

                {[...eligibleEntitlements].map((eligibleEntitlement, index) => {
                    const eligibleEntitlementText = getEligibleEntitlementText({
                        eligibleEntitlement,
                        eligibleEntitlementsLimits,
                    })

                    return (
                        <EntitlementLine
                            key={`${index}-${eligibleEntitlement}`}
                            entitlement={eligibleEntitlementText}
                            isUpgrade={true}
                        />
                    )
                })}
            </Stack>
        </Box>
    )
}

const EntitlementLine = ({entitlement, isUpgrade}: {entitlement: string; isUpgrade: boolean}) => (
    <Inline space={4} alignItems="center" css={{width: '280px'}}>
        {isUpgrade ? (
            <Check size={16} color={tokens.colorContentPositive} />
        ) : (
            <Close size={16} color={tokens.colorContentNegative} />
        )}
        <Text>{entitlement}</Text>
    </Inline>
)

const PlanChangeHelpSection = ({
    isMobile,
    onChatWithUs,
}: {
    isMobile: boolean
    onChatWithUs: () => void
}) => (
    <Box borderTop={tokens.borderPrimary}>
        <Inline paddingTop={24} space={16} alignItems="flex-start" css={{marginTop: 'auto'}}>
            {!isMobile && <Headset css={{width: '64px', height: '64px'}} />}
            <Box>
                <Text>
                    <Trans>Can we help?</Trans>
                </Text>
                <Text
                    variant="small-subtle"
                    css={{
                        paddingTop: tokens.spacing4,
                        paddingBottom: tokens.spacing8,
                        maxWidth: '400px',
                    }}
                >
                    <Trans>
                        If you have any questions around features, add-ons or pricing, just reach
                        out to us and we'll be happy to help.
                    </Trans>
                </Text>
                <ContactSupport chatLabel={t`Chat with us`} onClick={onChatWithUs} />
            </Box>
        </Inline>
    </Box>
)

const getListOfEntitlements = ({
    targetPlan,
    currentPlan,
    companyCurrency,
    locale,
    isLegacyPlan,
}: Pick<
    PlanChangeSummaryProps,
    'targetPlan' | 'currentPlan' | 'companyCurrency' | 'isLegacyPlan'
> & {
    locale: SupportedLanguage
}) => {
    const currentPlanConf = getPlanContentConfiguration(currentPlan)
    const targetPlanConf = getPlanContentConfiguration(targetPlan.planType)
    const isUpgrade = targetPlan.isUpgrade || isLegacyPlan

    let cashbackLine = ''
    let creditLine = ''
    const eligibleEntitlements = new Set<EligibleEntitlements>()
    const eligibleEntitlementsLimits = {
        cashback: 0,
        creditLine: '',
    }
    const baseEntitlements = isLegacyPlan
        ? targetPlanConf.allEntitlements
        : getArrayDifference(targetPlanConf.allEntitlements, currentPlanConf.allEntitlements)

    if (isUpgrade) {
        const targetPlanCashBackPercentage = targetPlanConf.cashback ?? 0

        cashbackLine = renderUpToCashbackLine(targetPlanCashBackPercentage)
        creditLine = renderUpToCreditLine({
            planConfiguration: targetPlanConf,
            currency: companyCurrency,
            locale,
        })

        // Cashback and credit line entitlements are shown in separate sections when upgrading
        // Eligible entitlement limits are only needed when upgrading to show in the tooltip
        if (cashbackLine) {
            eligibleEntitlements.add('CASHBACK')
            eligibleEntitlementsLimits.cashback = targetPlanCashBackPercentage
        }

        const value = targetPlanConf.overdraft?.[companyCurrency]

        if (creditLine) {
            eligibleEntitlements.add('CREDIT_LINE')
            eligibleEntitlementsLimits.creditLine = value
                ? formatMinorNumberToCurrency({
                      value,
                      options: {
                          currency: companyCurrency,
                          maximumFractionDigits: 1,
                          notation: 'compact',
                      },
                      locale,
                  })
                : ''
        }
    } else {
        cashbackLine = renderUpToCashbackLine(currentPlanConf.cashback ?? 0)
        creditLine = renderUpToCreditLine({
            planConfiguration: currentPlanConf,
            currency: companyCurrency,
            locale,
        })

        // Cashback and credit line entitlements are shown with the rest of entitlements when downgrading
        if (creditLine) {
            baseEntitlements.unshift(creditLine)
        }
        if (cashbackLine) {
            baseEntitlements.unshift(cashbackLine)
        }
    }

    return {
        baseEntitlements,
        eligibleEntitlements,
        eligibleEntitlementsLimits,
    }
}

type GetEligibleEntitlementTextArgs = {
    eligibleEntitlement: EligibleEntitlements
    eligibleEntitlementsLimits: EligibleEntitlementsLimits
}

const getEligibleEntitlementText = ({
    eligibleEntitlement,
    eligibleEntitlementsLimits,
}: GetEligibleEntitlementTextArgs) => {
    switch (eligibleEntitlement) {
        case 'CASHBACK':
            return renderUpToCashbackLine(eligibleEntitlementsLimits.cashback)
        case 'CREDIT_LINE':
            return renderUpToCreditPrice(eligibleEntitlementsLimits.creditLine)
        default:
            return exhaustiveCheck(eligibleEntitlement)
    }
}

type GetTooltipTextArgs = {
    eligibleEntitlements: Set<EligibleEntitlements>
    eligibleEntitlementsLimits: EligibleEntitlementsLimits
    planType: CpqRatePlanType
}

const getTooltipText = ({
    eligibleEntitlements,
    eligibleEntitlementsLimits,
    planType,
}: GetTooltipTextArgs) => {
    const {cashback, creditLine} = eligibleEntitlementsLimits

    const targetPlan = PlanTypeName[planType]

    if (eligibleEntitlements.size < 2) {
        if (eligibleEntitlements.has('CASHBACK')) {
            return t`Upgrade to the ${targetPlan} plan and chat with our support team to check your eligibility. If approved, you'll enjoy up to ${cashback}% cashback.`
        }

        if (eligibleEntitlements.has('CREDIT_LINE')) {
            return t`Upgrade to the ${targetPlan} plan and chat with our support team to check your eligibility. If approved, you'll access a credit line of up to ${creditLine}.`
        }
    }

    return t`Upgrade to the ${targetPlan} plan and chat with our support team to check your eligibility. If approved, you'll enjoy up to ${cashback}% cashback and access a credit line of up to ${creditLine}.`
}
