import {t, Trans} from '@lingui/macro'
import type {ReactNode} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import styled from 'styled-components'

import {
    Box,
    Button,
    Card,
    Link,
    List,
    ListIcon,
    ListItem,
    Tag,
    Text,
    tokens,
    Tooltip,
} from '@pleo-io/telescope'
import {Check} from '@pleo-io/telescope-icons'

import {PlanTypeName} from '@product-web/api-types/billing'
import {breakpoints} from '@product-web/styles/theme'
import {useUser} from '@product-web/user'
import {exhaustiveCheck} from '@product-web/utils'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'

import {toStringNoDecimal} from '../manage-plan/pricing-helpers'
import type {AllowedPlanType, PlanPrices} from '../plan-updates/plan-update.helper'
import {
    DashedText,
    getEssentialOrAdvancedBenefitListWithStarter,
    getStarterBenefitList,
    SmallText,
    starterAddonsPricesPerCurrencyMap,
} from '../plan-updates/plan-update.helper'

const getPlanColumnConf = (
    plan: AllowedPlanType,
    currency: string,
): {
    title: string
    benefitsIncluded: string[]
    benefitsNotIncluded: string[]
    addons: {title: ReactNode; tooltip?: string}[]
} => {
    switch (plan) {
        case 'ESSENTIAL':
            return {
                title: PlanTypeName[plan],
                benefitsIncluded: [
                    t`Individual cards`,
                    t`Real-time expense tracking`,
                    t`Analytics dashboard`,
                    t`Spending limits`,
                    t`Subscription management`,
                    t`Approval workflow`,
                    t`Reimbursements`,
                    t`Invoice payments`,
                ],
                benefitsNotIncluded: [
                    t`Budgets`,
                    t`Vendor cards`,
                    t`Open API`,
                    t`Single Sign-On`,
                    t`Customer Success Manager`,
                ],
                addons: getEssentialOrAdvancedBenefitListWithStarter({
                    priceExtBookkeeper: toStringNoDecimal(0, currency),
                }),
            }
        case 'ADVANCED':
        case 'BEYOND':
            return {
                title: PlanTypeName[plan],
                benefitsIncluded: [
                    t`Individual cards`,
                    t`Real-time expense tracking`,
                    t`Analytics dashboard`,
                    t`Spending limits`,
                    t`Subscription management`,
                    t`Approval workflow`,
                    t`Reimbursements`,
                    t`Invoice payments`,
                    t`Budgets`,
                    t`Vendor cards`,
                    t`Open API`,
                    t`Single Sign-On`,
                    t`Customer Success Manager`,
                ],
                benefitsNotIncluded: [],
                addons: getEssentialOrAdvancedBenefitListWithStarter({
                    priceExtBookkeeper: toStringNoDecimal(0, currency),
                }),
            }
        case 'STARTER':
            return {
                title: PlanTypeName[plan],
                benefitsIncluded: [t`2 company cards`, t`Real-time expense tracking`],
                benefitsNotIncluded: [
                    t`Analytics dashboard`,
                    t`Spending limits`,
                    t`Subscription management`,
                    t`Approval workflow`,
                    t`Reimbursements`,
                    t`Invoice payments`,
                    t`Budgets`,
                    t`Vendor cards`,
                    t`Open API`,
                    t`Single Sign-On`,
                    t`Customer Success Manager`,
                ],
                addons: getStarterBenefitList({
                    priceAtmWithdrawal: starterAddonsPricesPerCurrencyMap.ATM_WITHDRAWALS[currency],
                    priceExtBookkeeper:
                        starterAddonsPricesPerCurrencyMap.EXTERNAL_BOOKKEEPER[currency],
                }),
            }
        default:
            return exhaustiveCheck(plan)
    }
}

interface PlanSelectionColumnProps {
    currentPlan: AllowedPlanType
    onGetStarted: () => void
    planChangeBlocked: boolean
    planPrices: PlanPrices
    planType: AllowedPlanType
    isButtonLoading?: boolean
    isButtonDisabled?: boolean
    isCompanyVerified?: boolean
}

export const PlanSelectionColumn = ({
    currentPlan,
    onGetStarted,
    planChangeBlocked,
    planPrices,
    planType,
    isButtonLoading = false,
    isButtonDisabled = false,
    isCompanyVerified = true,
}: PlanSelectionColumnProps) => {
    const user = useUser()
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.tabletUp}`)

    const currency = planPrices?.currency ?? ''
    const planColumnConf = getPlanColumnConf(planType, currency)
    const planToFocus =
        currentPlan === 'ESSENTIAL' || currentPlan === 'ADVANCED' ? 'ADVANCED' : 'ESSENTIAL'
    const isFocused = planToFocus === planType

    const renderPrice = () => {
        const price = planPrices?.prices?.monthlyDiscounted
        if (price || price === 0) {
            return (
                <Box css={{flexGrow: 1, alignSelf: 'start'}}>
                    <Text variant="medium-default" weight="medium" color="colorContentStatic">
                        <Trans>from</Trans>
                    </Text>
                    <Text variant="3xlarge-accent">{toStringNoDecimal(price, currency)}</Text>
                </Box>
            )
        }

        return null
    }

    const renderCompanyVerificationToolTip = (children: ReactNode) => {
        const tooltipText = t`Company verification in progress. Please wait until you're fully onboarded before changing plans.`

        return isCompanyVerified ? (
            <>{children}</>
        ) : (
            <Tooltip content={tooltipText}>{children}</Tooltip>
        )
    }

    const renderActionSection = () => {
        if (planType === currentPlan) {
            return (
                <Box css={{justifySelf: 'center'}} py={8}>
                    <Tag variant="gray">
                        <Trans>YOUR CURRENT PLAN</Trans>
                    </Tag>
                </Box>
            )
        }

        if (planChangeBlocked) {
            return (
                <BorderedText>
                    <Trans>To change your plan, please contact your account executive.</Trans>
                </BorderedText>
            )
        }

        if (planType === 'STARTER') {
            const usersLimitReached = (user?.company?.numberOfUsers ?? 0) > 3
            if (usersLimitReached) {
                return (
                    <BorderedText>
                        <Trans>
                            You have more than 3 users. To continue with {PlanTypeName[planType]},
                            remove users on the{' '}
                            <Link as={RouterLink} to="/people" css={{fontSize: tokens.fontSmall}}>
                                People page
                            </Link>
                            .
                        </Trans>
                    </BorderedText>
                )
            }

            return renderCompanyVerificationToolTip(
                <Button
                    variant="secondary"
                    css={{justifySelf: 'center'}}
                    onClick={onGetStarted}
                    loading={isButtonLoading}
                    disabled={isButtonDisabled}
                >
                    <Trans>Downgrade to {PlanTypeName[planType]}</Trans>
                </Button>,
            )
        }

        return renderCompanyVerificationToolTip(
            <Button
                variant={isFocused ? 'primary' : 'secondary'}
                css={{justifySelf: 'center'}}
                onClick={onGetStarted}
                loading={isButtonLoading}
                disabled={isButtonDisabled}
            >
                <Trans>Continue with {PlanTypeName[planType]}</Trans>
            </Button>,
        )
    }

    return (
        <PlanColumnWrapper $isFocused={isFocused}>
            <Box px={20} pt={32}>
                {/* Plan info */}
                <Text variant="xlarge-accent">{planColumnConf.title}</Text>
                <ActionSection>
                    {renderPrice()}
                    {renderActionSection()}
                </ActionSection>
                {/* Features */}
                <List>
                    {planColumnConf.benefitsIncluded.map((item) => (
                        <CheckedListItem key={item}>{item}</CheckedListItem>
                    ))}
                    {planColumnConf.benefitsNotIncluded.map((item) => (
                        <StrikedListItem key={item}>{item}</StrikedListItem>
                    ))}
                </List>
            </Box>
            {/* Addons */}
            <Card.Divider />
            <Box px={20} pt={14}>
                <List>
                    {planColumnConf.addons.map((addon) => (
                        <ListItem
                            key={addon.title?.toString()}
                            css={{marginBottom: tokens.spacing2}}
                        >
                            {addon?.tooltip && !isMobile ? (
                                <Tooltip content={addon?.tooltip}>
                                    <DashedText>{addon.title}</DashedText>
                                </Tooltip>
                            ) : (
                                <SmallText>{addon.title}</SmallText>
                            )}
                        </ListItem>
                    ))}
                </List>
            </Box>
        </PlanColumnWrapper>
    )
}

const CheckedListItem = ({children}: {children?: ReactNode}) => (
    <ListItem css={{display: 'flex', alignItems: 'center', marginBottom: tokens.spacing4}}>
        <ListIcon
            as={Check}
            color={tokens.green700}
            size={16}
            css={{marginRight: tokens.spacing6, marginTop: 0}}
        />
        <Text variant="small-subtle" color="colorContentStatic">
            {children}
        </Text>
    </ListItem>
)

const StrikedText = styled(Text).attrs({variant: 'small-subtle'})`
    color: ${tokens.colorContentInteractiveDisabled};
    margin-left: ${tokens.spacing20};
    text-decoration: line-through;
    margin-bottom: ${tokens.spacing4};
`

const StrikedListItem = ({children}: {children?: ReactNode}) => (
    <ListItem css={{marginBottom: tokens.spacing4}}>
        <StrikedText>{children}</StrikedText>
    </ListItem>
)

/* Parent wrapper component must be a flexbox */
const PlanColumnWrapper = styled(Card)<{$isFocused: boolean}>`
    border-color: ${({$isFocused}) => $isFocused && tokens.colorBorderInteractiveSelected};
    max-width: 314px;
    text-align: start;

    @media (min-width: ${breakpoints.tabletUp}) {
        flex: 0 1 314px;
        width: 0;
    }
`

const BorderedText = styled(Text).attrs({variant: 'small-subtle'})`
    border: ${tokens.borderPrimary};
    border-radius: ${tokens.arc8};
    padding-block: ${tokens.spacing8};
    padding-inline: ${tokens.spacing12};
`

const ActionSection = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: '140px';
    margin-bottom: ${tokens.spacing20};
    margin-top: ${tokens.spacing4};
`
