import {t, Trans} from '@lingui/macro'
import {useNavigate} from 'react-router-dom'
import styled from 'styled-components'

import {Box, ProgressBar, Stack, Text, tokens} from '@pleo-io/telescope'

import {useSpendActivationGuide} from '../use-spend-activation-guide'

interface ProgressCardProps {
    isTablet: boolean
}

export const ProgressCard = ({isTablet}: ProgressCardProps) => {
    const navigate = useNavigate()

    const {isEnabled} = useSpendActivationGuide()

    if (!isEnabled) {
        return null
    }

    return (
        <Wrapper onClick={() => navigate('/recurring-vendors/setup-guide')}>
            <StyledBox
                isTablet={isTablet}
                borderRadius={8}
                paddingTop={12}
                paddingX={16}
                paddingBottom={16}
            >
                <Stack space={8}>
                    <Text>
                        <Trans>Set up recurring spend</Trans>
                    </Text>

                    <ProgressBar
                        aria-label={t`progress bar showing percentage of recurring spend setup`}
                        variant="tall"
                        percent={50}
                        fillColor={'green500'}
                    />
                </Stack>
            </StyledBox>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    cursor: pointer;
    padding: ${tokens.spacing12} ${tokens.spacing16} ${tokens.spacing16};
    justify-self: stretch;
`

interface StyledBoxProps {
    isTablet: boolean
}

const StyledBox = styled(Box)<StyledBoxProps>`
    background-color: ${({isTablet}) =>
        isTablet ? tokens.colorBackgroundInteractiveLoud : tokens.colorBackgroundInteractive};
`
