import {t, Trans} from '@lingui/macro'
import {useState} from 'react'
import Helmet from 'react-helmet'
import {Link} from 'react-router-dom'

import {Accordion, Box, Text} from '@pleo-io/telescope'

import {useFlags} from '@product-web/flags'
import PageNotFound from '@product-web/routes/page-not-found/page-not-found'
import {useToaster} from '@product-web/toaster'
import {Page} from '@product-web-features/ui-page/page'

import {GiveFeedback} from './give-feedback/give-feedback'
import {PricingDetailsForm} from './pricing-details-form'
import {PricingDetailsFormSkeleton} from './pricing-details-form-skeleton'
import {PricingResults} from './pricing-results'
import {PricingResultsSkeleton} from './pricing-results-skeleton'

import {bff} from '../../bff-hooks'
import {SplitContent, useSplitContentMediaQuery} from '../../components/split-content'
import pricingCalculator from '../../images/pricing-calculator.svg'
import type {CalculatePricingInput} from '../../index.bff'

export const PricingCalculator = () => {
    const {partnerPricingCalculator, partnerPricingCalculatorFeedback} = useFlags()
    const {showToast} = useToaster()
    const isNarrowViewport = useSplitContentMediaQuery()
    const defaultPayload = {
        plan: 'ESSENTIAL' as const,
        usersCount: 3,
        billingFrequency: 'MONTHLY' as const,
        cashback: false,
        expectedMonthlySpend: 0,
    }
    const [payload, setPayload] = useState<CalculatePricingInput>(defaultPayload)
    const [isDirty, setIsDirty] = useState(false)

    const {data, isFetching, isLoading} = bff.pricingCalculator.calculatePricing.useQuery(payload, {
        onError: () => {
            showToast(t`Please try again, or contact support.`, {
                title: t`Something went wrong`,
                level: 'error',
            })
        },
        keepPreviousData: true,
    })

    if (!partnerPricingCalculator) {
        return <PageNotFound />
    }

    return (
        <Page layout="wide" showNav>
            <Helmet>
                <title>{t`Pleo Pricing Calculator`}</title>
            </Helmet>
            <Page.Header>
                <Page.SpaceBetween>
                    <Page.Title>
                        <Trans>Pleo Pricing Calculator</Trans>
                    </Page.Title>
                    <Page.Actions>
                        <Link
                            to="/partner/onboard"
                            state={{fromPath: '/partner/pricing-calculator'}}
                        >
                            <Trans>Onboard client</Trans> &rarr;
                        </Link>
                    </Page.Actions>
                </Page.SpaceBetween>
            </Page.Header>
            <SplitContent>
                <SplitContent.Main flex={4}>
                    <img src={pricingCalculator} alt="" />
                    <Box mb={24}>
                        <Text as="h2" variant="3xlarge-accent">
                            <Trans>Overview</Trans>
                        </Text>
                        <Text color="colorContentStaticQuiet">
                            <Trans>
                                This is what will appear on your client's quote and invoice.
                            </Trans>
                        </Text>
                    </Box>
                    {isLoading && <PricingResultsSkeleton />}
                    {data && (
                        <PricingResults
                            data-testid="price-quote"
                            isDirty={isDirty}
                            pricingQuote={data}
                        />
                    )}
                    {!isNarrowViewport && partnerPricingCalculatorFeedback && (
                        <GiveFeedback isLoading={isLoading} />
                    )}
                </SplitContent.Main>
                <SplitContent.Right>
                    <Accordion css={{'margin-top': '28px'}} defaultValue={['pricing-details']}>
                        <Accordion.Item value="pricing-details">
                            <Accordion.Header headingLevel="h2" heading={t`Pricing details`} />
                            <Accordion.Content>
                                {isLoading && <PricingDetailsFormSkeleton />}
                                {data && (
                                    <PricingDetailsForm
                                        isFetching={isFetching}
                                        pricingQuote={data}
                                        setIsDirty={setIsDirty}
                                        setPayload={setPayload}
                                    />
                                )}
                            </Accordion.Content>
                        </Accordion.Item>
                    </Accordion>
                    {isNarrowViewport && partnerPricingCalculatorFeedback && (
                        <GiveFeedback isLoading={isLoading} />
                    )}
                </SplitContent.Right>
            </SplitContent>
            <Box pb={56} />
        </Page>
    )
}
