import {t} from '@lingui/macro'
import type {FC, ReactNode} from 'react'
import {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import styled, {css} from 'styled-components'

import {IconButton, Inline, Stack, Text, tokens} from '@pleo-io/telescope'
import {ArrowLeft, Plus} from '@pleo-io/telescope-icons'
import type {SpacingValues} from '@pleo-io/telescope-tokens/dist/spacing.types'

import tracking from '@product-web/analytics'
import {useLoggedInAccounts} from '@product-web/auth--accounts'
import {useTokenData} from '@product-web/auth--session/context'
import {useMobileNavigationVisibility} from '@product-web/navigation/navigation-context'
import {customColorSchemeTokens} from '@product-web/styles/custom-tokens'
import {breakpoints} from '@product-web/styles/theme'
import {Keys} from '@product-web/web-platform/keyboard'
import {useKeyPress} from '@product-web/web-platform/use-keypress'
import {useMediaQuery} from '@product-web/web-platform/use-media-query'
import {RequestEntitiesModal} from '@product-web-features/multi-entity/self-initiated-expansion/request-entities-modal'

import {EntityCard} from './entity-card'
import type {VerificationStatus} from './lib/use-verification-status'
import {SelfExpansionEntityCard} from './self-expansion-entity-card'

import {bff} from '../bff-hooks'
import {getSwitchAccountRedirectLocation} from '../get-switch-account-redirect-location'
import {useInternalNavigationContext} from '../navigation-internal-provider'

interface PanelTitleWrapperProps {
    title: string
    children?: ReactNode
}

function GroupTitle({title, children}: PanelTitleWrapperProps) {
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp}`)

    return (
        <GroupTitleText alignY="center" justifyContent={'space-between'}>
            <Text variant={isTablet ? 'xlarge-accent' : 'large-accent'}>{title}</Text>
            {children}
        </GroupTitleText>
    )
}

interface Entity {
    companyId: string
    companyName?: string
    walletBalance?: {value?: number; currency?: string}
    exportData?: {
        missingReceiptsCount?: number
        exportQueuedCount?: number
    }
    verificationStatus?: VerificationStatus
    role: 'bookkeeper' | 'owner' | 'member'
    isActive?: boolean
    isSpendingEntity?: boolean
}

interface EntitiesGroup {
    id: string
    type: 'organization' | 'company'
    name: string
    entities: Entity[]
}

interface EntitySwitcherPanelProps {
    isPanelVisible: boolean
    setPanelVisible: (visible: boolean) => void
}

const SWITCH_ENTITY_DELAY_IN_MS = 500
const ENABLE_SWITCH_ENTITY_DELAY_IN_MS = 3000

export const EntitySwitcherPanel: FC<EntitySwitcherPanelProps> = ({
    isPanelVisible,
    setPanelVisible,
}) => {
    const isTablet = useMediaQuery(`(max-width: ${breakpoints.tabletMedUp})`)

    const {
        navItemsWidth: shift,
        entitySwitcherWidth,
        selectedEntityId,
        setSelectedEntityId,
        canChangeEntity,
        setCanChangeEntity,
    } = useInternalNavigationContext()

    const {switchAccount, accounts} = useLoggedInAccounts()
    const location = useLocation()
    const navigate = useNavigate()
    const {setIsMobileNavigationVisible} = useMobileNavigationVisibility()
    const tokenPayload = useTokenData()
    const email = tokenPayload?.user.email

    useKeyPress(Keys.ESCAPE, () => {
        if (isTablet && isPanelVisible) {
            setPanelVisible(false)
        }
    })

    const {data: groupedEntities = []} = bff.navigation.getEntitySwitcherPanelData.useQuery(
        undefined,
        {
            keepPreviousData: true,
            meta: {dangerouslyPreventDefaultQueryInvalidationOnMutation: true}, // Mitigating traffic for inc-938
        },
    )

    const entities = groupedEntities.flatMap((entityGroup) => entityGroup.entities)
    const selectedEntity = entities.find((entity) => entity.companyId === selectedEntityId)

    const containsOrganizationAdmin = entities.some(
        (entity) => entity.role === 'owner' && entity.organizationId,
    )
    const containsNonOrgAdmin = entities.some(
        (entity) => entity.role === 'owner' && !entity.organizationId,
    )

    const handleBackButtonClick = () => {
        setPanelVisible(false)
    }

    useEffect(() => {
        // We're using API response to determine when we can enable company switching
        // So if there will be a big latency during API call we still
        // want allow user to switch the entity
        let timeout: ReturnType<typeof setTimeout>
        if (!canChangeEntity) {
            timeout = setTimeout(() => {
                setCanChangeEntity(true)
            }, ENABLE_SWITCH_ENTITY_DELAY_IN_MS)
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [canChangeEntity])

    const isEntitySwitchInProgress = !email || !selectedEntity || !canChangeEntity

    const handleEntityCardClick = async (companyId: string, redirectLocation?: string) => {
        const isSwitchingDisabled =
            isEntitySwitchInProgress || companyId === selectedEntity.companyId

        const targetEntity = entities.find((entity: Entity) => entity.companyId === companyId)
        const isTargetSpendingEntity = !!targetEntity?.isSpendingEntity
        const numberOfExpensesInQueue = targetEntity?.exportData?.exportQueuedCount
        const numberOfMissingReceipts = targetEntity?.exportData?.missingReceiptsCount

        tracking.switchEntity({
            currentViewingEntityId: selectedEntityId ?? undefined,
            entityId: companyId,
            isSpendingEntity: isTargetSpendingEntity,
            isSwitchingEnabled: !isSwitchingDisabled,
            numberOfActiveAccounts: accounts.length ?? 0,
            numberOfEntities: entities.length,
            numberOfExpensesInQueue,
            numberOfMissingReceipts,
            source: 'entity-switcher',
            type: targetEntity!.role,
        })

        if (isSwitchingDisabled) {
            // we do not let user switching to the same account
            // also we do not let to switch account if switching is already in progress
            return
        }

        setCanChangeEntity(false)
        setSelectedEntityId(companyId)

        const isOrganizationAdmin =
            selectedEntity?.role === 'owner' && !!selectedEntity?.organizationId

        const redirectTo =
            redirectLocation ??
            getSwitchAccountRedirectLocation(location, {
                isSpendingEntity: selectedEntity.isSpendingEntity,
                isOrganizationAdmin,
            })

        if (isTablet) {
            // hiding both entity switch and navigation panels on mobile devices
            // before switching entity
            setPanelVisible(false)
            setIsMobileNavigationVisible(false)
            setTimeout(async () => {
                // For mobile devices we want user to see the main app
                // content before starting the switch account process
                await switchAccount({email, companyId, location: redirectTo})
            }, SWITCH_ENTITY_DELAY_IN_MS)
        } else {
            await switchAccount({email, companyId, location: redirectTo})
        }
    }

    const handleVerifyClick = async (companyId: string) => {
        if (companyId !== selectedEntityId && email) {
            await handleEntityCardClick(companyId, '/company-verification')
        } else if (companyId === selectedEntityId) {
            if (isTablet) {
                // hiding both entity switch and navigation panels on mobile devices
                // before switching entity
                setPanelVisible(false)
                setIsMobileNavigationVisible(false)
            }

            navigate('/company-verification')
        }
    }

    return (
        <Panel $isVisible={isPanelVisible} $width={entitySwitcherWidth} $shift={shift}>
            {isTablet && (
                <BackButtonWrapper alignY="center">
                    <BackButton
                        size={'small'}
                        onClick={handleBackButtonClick}
                        Icon={ArrowLeft}
                        variant="secondary"
                        aria-label={t`Back`}
                        tooltipProps={{
                            content: t`Back`,
                            side: 'right',
                        }}
                    />
                </BackButtonWrapper>
            )}
            <Scrollable>
                <ScrollableStack>
                    <NonBookkeeperGroup
                        groupedEntities={groupedEntities}
                        selectedEntityId={selectedEntityId}
                        onEntityCardClick={handleEntityCardClick}
                        onVerifyClick={handleVerifyClick}
                        containsOrganizationAdmin={containsOrganizationAdmin}
                        containsNonOrgAdmin={containsNonOrgAdmin}
                        switchDisabled={isEntitySwitchInProgress}
                    />
                    <BookkeeperGroup
                        groupedEntities={groupedEntities}
                        selectedEntityId={selectedEntityId}
                        onEntityCardClick={handleEntityCardClick}
                        onVerifyClick={handleVerifyClick}
                        switchDisabled={isEntitySwitchInProgress}
                    />
                </ScrollableStack>
            </Scrollable>
        </Panel>
    )
}

function NonBookkeeperGroup({
    groupedEntities,
    selectedEntityId,
    onEntityCardClick,
    onVerifyClick,
    containsOrganizationAdmin,
    containsNonOrgAdmin,
    switchDisabled,
}: {
    groupedEntities: EntitiesGroup[]
    selectedEntityId: string | null
    onEntityCardClick: (companyId: string) => void
    onVerifyClick: (companyId: string) => void
    containsOrganizationAdmin: boolean
    containsNonOrgAdmin: boolean
    switchDisabled: boolean
}) {
    const [isRequestEntitiesModalOpen, setIsRequestEntitiesModalOpen] = useState(false)
    const nonBookKeeperEntitiesGroup = groupedEntities.filter(
        (entitiesGroup) => entitiesGroup.entities[0].role !== 'bookkeeper',
    )

    const title = containsOrganizationAdmin ? t`Entities` : t`Your company`

    const showAddEntitiesButton = containsOrganizationAdmin || containsNonOrgAdmin

    const AddEntitiesIconRef = useRef<HTMLButtonElement>(null)

    const handleAddEntitiesClick = (isNewEntityCard?: boolean) => {
        tracking.selfExpansionModalOpened({
            source: isNewEntityCard ? 'new-entity-card' : 'plus-icon',
            hasOrgAccess: !!containsOrganizationAdmin,
        })
        setIsRequestEntitiesModalOpen(true)

        AddEntitiesIconRef?.current?.blur()
    }

    const handleAddEntitiesKeyPress = () => {
        tracking.selfExpansionModalOpened({
            source: 'plus-icon',
            hasOrgAccess: !!containsOrganizationAdmin,
        })
        setIsRequestEntitiesModalOpen(true)
    }

    return (
        <Stack stretch>
            {nonBookKeeperEntitiesGroup.length > 0 && (
                <GroupTitle title={title}>
                    {showAddEntitiesButton && (
                        <AddCompanyIcon
                            disabled={switchDisabled}
                            size={'small'}
                            onClick={() => handleAddEntitiesClick()}
                            onKeyPress={handleAddEntitiesKeyPress}
                            Icon={Plus}
                            ref={AddEntitiesIconRef}
                            variant="secondary"
                            aria-label={t`Add entity`}
                            tooltipProps={{
                                content: t`Add entity`,
                                side: 'right',
                            }}
                            data-testid={'add-entities-button'}
                        />
                    )}
                </GroupTitle>
            )}

            {nonBookKeeperEntitiesGroup.map((group, index) => (
                <EntityCardsGroup
                    key={group.id}
                    group={group}
                    activeCompanyId={selectedEntityId}
                    onClick={onEntityCardClick}
                    onVerifyClick={onVerifyClick}
                    marginTop={index === 0 ? 4 : 0}
                    switchDisabled={switchDisabled}
                />
            ))}
            {containsNonOrgAdmin && (
                <SelfExpansionEntityCard
                    onClick={() => handleAddEntitiesClick(true)}
                    disabled={switchDisabled}
                />
            )}
            <RequestEntitiesModal
                isOrganizationAdmin={containsOrganizationAdmin}
                isOpen={isRequestEntitiesModalOpen}
                setIsOpen={setIsRequestEntitiesModalOpen}
            />
        </Stack>
    )
}

function BookkeeperGroup({
    groupedEntities,
    selectedEntityId,
    onEntityCardClick,
    onVerifyClick,
    switchDisabled,
}: {
    groupedEntities: EntitiesGroup[]
    selectedEntityId: string | null
    onEntityCardClick: (companyId: string) => void
    onVerifyClick: (companyId: string) => void
    switchDisabled: boolean
}) {
    const bookkeeperEntitiesGroup = groupedEntities.filter(
        (entitiesGroup) => entitiesGroup.entities[0].role === 'bookkeeper',
    )

    return (
        <Stack stretch>
            {bookkeeperEntitiesGroup.length > 0 && <GroupTitle title={t`Bookkeeping`} />}
            {bookkeeperEntitiesGroup.map((group, index, groups) => (
                <EntityCardsGroup
                    key={group.id}
                    showSeparatorAfterGroup={
                        (group.type === 'company' && groups[index + 1]?.type === 'organization') ||
                        (group.type === 'organization' && groups[index + 1]?.type === 'company')
                    }
                    groupSeparatorMarginBottom={
                        group.type === 'organization' && groups[index + 1]?.type === 'company'
                            ? tokens.spacing12
                            : '0'
                    }
                    marginTop={index === 0 ? 4 : 0}
                    showOrganizationName={group.type === 'organization'}
                    group={group}
                    activeCompanyId={selectedEntityId}
                    onClick={onEntityCardClick}
                    onVerifyClick={onVerifyClick}
                    switchDisabled={switchDisabled}
                />
            ))}
        </Stack>
    )
}

interface EntityCardsGroupProps {
    showSeparatorAfterGroup?: boolean
    showOrganizationName?: boolean
    activeCompanyId: string | null
    group: EntitiesGroup
    onClick: (companyId: string) => void
    onVerifyClick: (companyId: string) => void
    groupSeparatorMarginBottom?: string
    marginTop?: SpacingValues | 0
    switchDisabled: boolean
}

const EntityCardsGroup: FC<EntityCardsGroupProps> = ({
    showSeparatorAfterGroup,
    showOrganizationName,
    group,
    activeCompanyId,
    onClick,
    onVerifyClick,
    groupSeparatorMarginBottom,
    marginTop,
    switchDisabled,
}) => {
    return (
        <EntityCardsGroupStack stretch mt={marginTop}>
            {showOrganizationName && (
                <OrganizationName weight="medium" variant="small-subtle" truncate>
                    {group.name}
                </OrganizationName>
            )}
            <Stack stretch space={12}>
                {group.entities.map((entity) => (
                    <EntityCard
                        testId={`entity-card-${entity.companyId}`}
                        key={entity.companyId}
                        isActive={activeCompanyId === entity.companyId}
                        verificationStatus={entity.verificationStatus}
                        isHomeEntity={entity.isSpendingEntity}
                        companyName={entity.companyName}
                        role={entity.role}
                        missingReceiptsCount={entity.exportData?.missingReceiptsCount}
                        exportQueuedCount={entity.exportData?.exportQueuedCount}
                        walletBalance={entity.walletBalance}
                        onClick={() => onClick(entity.companyId)}
                        onVerifyClick={() => onVerifyClick(entity.companyId)}
                        disabled={switchDisabled}
                    />
                ))}
            </Stack>
            {showSeparatorAfterGroup && (
                <GroupSeparator $marginBottom={groupSeparatorMarginBottom} />
            )}
        </EntityCardsGroupStack>
    )
}

const Panel = styled.div<{$isVisible: boolean; $width: number; $shift: number}>`
    position: fixed;
    top: 0;
    bottom: 0;
    left: ${({$isVisible, $shift}) => ($isVisible ? `${$shift}px` : '0')};
    display: flex;
    width: ${({$width}) => $width}px;
    height: 100vh;
    box-sizing: border-box;
    font-size: ${tokens.fontSmall};
    flex-direction: column;
    background-color: ${customColorSchemeTokens.colorBackgroundEntitySwitcherPanel};
    z-index: ${tokens.zIndexSurface};
    transition: ${`all ${tokens.smoothInOut}`};

    @media (max-width: ${breakpoints.tabletMedUp}) {
        z-index: ${tokens.zIndexWindow};
        left: 100vw;
        border: none;
        width: 100vw;

        ${({$isVisible}) =>
            $isVisible &&
            css`
                left: 0;
            `}
    }
`

const Scrollable = styled(Inline)`
    height: 100%;
    overflow: auto;
`

const ScrollableStack = styled(Stack)`
    grid-gap: 0;
    justify-items: stretch;
    padding-left: ${tokens.spacing20};
    padding-right: ${tokens.spacing20};
    margin-bottom: ${tokens.spacing48};

    @media (max-width: ${breakpoints.tabletMedUp}) {
        padding-left: ${tokens.spacing56};
        padding-right: ${tokens.spacing56};
        margin-bottom: ${tokens.spacing32};
    }

    @media (max-width: ${breakpoints.mobileLrgUp}) {
        padding-left: ${tokens.spacing24};
        padding-right: ${tokens.spacing24};
    }
`

const GroupTitleText = styled(Inline)`
    position: sticky;
    top: 0;
    padding: 36px 0 ${tokens.spacing20} 0;

    @media (max-width: ${breakpoints.tabletMedUp}) {
        padding: ${tokens.spacing32} 0 ${tokens.spacing20} 0;
    }

    @media (max-width: ${breakpoints.mobileLrgUp}) {
        padding: ${tokens.spacing8} 0 ${tokens.spacing12} 0;
    }

    background-color: ${customColorSchemeTokens.colorBackgroundEntitySwitcherPanel};
    z-index: ${tokens.zIndexSurface};
`

const GroupSeparator = styled.div<{$marginBottom?: string}>`
    margin: ${tokens.spacing24} ${tokens.spacing20} ${({$marginBottom}) => $marginBottom ?? '0'}
        ${tokens.spacing20};
    border: 0;
    border-top: ${tokens.sizeBorderDefault} solid ${tokens.colorBorderStaticLoud};
`

const OrganizationName = styled(Text)`
    margin-bottom: ${tokens.spacing12};
    margin-top: ${tokens.spacing12};
`

const AddCompanyIcon = styled(IconButton)`
    height: ${tokens.spacing24};
    width: ${tokens.spacing24};
`

const BackButton = styled(IconButton)`
    height: ${tokens.spacing32};
    width: ${tokens.spacing32};
    margin-left: ${tokens.spacing12};
`

const BackButtonWrapper = styled(Inline)`
    height: 64px;
    flex-shrink: 0;
`

const EntityCardsGroupStack = styled(Stack)`
    margin-bottom: ${tokens.spacing12};

    @media (max-width: ${breakpoints.mobileLrgUp}) {
        margin-bottom: ${tokens.spacing8};
    }
`
