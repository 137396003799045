/* eslint-disable react/jsx-key */
import {t, Trans} from '@lingui/macro'

import {Link} from '@pleo-io/telescope'

import type {AccountingSystemDetails} from '@product-web/api-types/accounting'
import {
    BillInvoiceFeatureProperty,
    ExportFeature,
    PocketFeatureProperty,
    RepresentationFeatureProperty,
    ReverseFeatureProperty,
    SystemName,
} from '@product-web/api-types/accounting'
import {SupportedLanguage} from '@product-web/i18n'
import Country from '@product-web/locale/country'
import {getHelpCentreArticleLinkIntercom} from '@product-web/locale/helpers'

export const getAccountingSystemConfiguration = (system: string) => {
    return generateSystems()[system]
}

export const findDirectAccountingSystem = (
    accountingSystemValue: string,
): AccountingSystemDetails | null => {
    const systems = generateSystems()
    const systemKeys = Object.keys(systems)
    const system = systemKeys.find((key) => {
        const selectedSystem = systems[key]
        return (
            selectedSystem.direct &&
            (selectedSystem.directSystemValue === accountingSystemValue ||
                selectedSystem.value === accountingSystemValue)
        )
    })
    return system ? systems[system] : null
}

export const generateSystems = (): {[name: string]: AccountingSystemDetails} => ({
    holded: {
        value: 'holded',
        name: SystemName.HOLDED,
        csv: true,
        reverse: false,
        noReverse: true,
        contraAccount: true,
        supportsGenericVat: true,
        countries: [Country.ES],
        hasAccountDefaultTaxCode: false,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
        },
    },
    holded_direct: {
        value: 'holded_direct',
        name: SystemName.HOLDED_DIRECT,
        csv: false,
        direct: true,
        reverse: false,
        noReverse: true,
        contraAccount: true,
        supportsGenericVat: true,
        hasAccountDefaultTaxCode: false,
        apiKeyIntegration: true,
        apiKeySystemContextName: t`API integration code`,
        hasExternalAccountId: true,
        hasExternalAccounts: true,
        useCashbooksOptionsAsEmployeeContraAccount: true,
        countries: [Country.ES],
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
        },
        directSystemValue: 'holded',
        apiKeyIntegrationHelpInstructions: HOLDED_DIRECT_INSTRUCTIONS,
    },
    datev: {
        value: 'datev',
        name: SystemName.DATEV,
        csv: true,
        reverse: false,
        contraAccount: true,
        employeeContraAccount: true,
        supportsGenericVat: true,
        countries: [Country.DE],
        hasAccountDefaultTaxCode: false,
        consultantNumber: true,
        clientNumber: true,
        fiscalYear: true,
        normalTaxTableWithType: [Country.AT],
        costCenter1TagGroup: {
            title: () => t`Tag group for cost center`,
            description: () => t`Tag group value that will be exported into cost center`,
            codeColumn: true,
            nameColumn: false,
        },
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: true},
            ],
        },
    },
    datev_direct: {
        value: 'datev_direct',
        name: SystemName.DATEV_DIRECT,
        csv: false,
        direct: true,
        cashbook: false,
        contraAccount: true,
        countries: [Country.DE],
        invoicesPaymentContraAccount: true,
        supportsGenericVat: true,
        normalTaxTableWithType: [Country.AT],
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: true},
            ],
            [ExportFeature.BILL_INVOICE]: [
                {name: BillInvoiceFeatureProperty.SHOW_EXPORT_IN_BILLS_DASHBOARD, required: true},
            ],
        },
        directSystemValue: 'datev',
        supportsTags: true,
        tagsPromptDescription: t`'Kost1' and 'Kost2'`,
        helpCentre: {
            folderId: 103000620247,
            supportedLanguages: [SupportedLanguage.DE],
        },
    },
    fortnox: {
        value: 'fortnox',
        name: SystemName.FORTNOX,
        csv: false,
        reverse: true,
        direct: true,
        cashbook: true,
        contraAccount: true,
        supportsGenericVat: true,
        hasExternalAccounts: true,
        countries: [Country.SE],
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
            ],
            [ExportFeature.REPRESENTATION]: [
                {
                    name: RepresentationFeatureProperty.DEDUCTIBLE_COST_ACCOUNT,
                    required: true,
                    countries: [Country.SE],
                },
                {
                    name: RepresentationFeatureProperty.NON_DEDUCTIBLE_ACCOUNT,
                    required: true,
                    countries: [Country.SE],
                },
            ],
            [ExportFeature.REVERSE]: [
                {
                    name: ReverseFeatureProperty.DEFAULT_INGOING_TAX_ACCOUNT,
                    required: true,
                    countries: [Country.SE],
                },
            ],
        },
        supportsTags: true,
        tagsPromptDescription: t`Cost Centers and Projects`,
        helpCentre: {
            folderId: 103000620252,
            supportedLanguages: [SupportedLanguage.SV],
        },
    },
    sie: {
        value: 'sie',
        name: SystemName.SIE,
        csv: true,
        direct: false,
        custom: true,
        hidden: false,
        reverse: true,
        supportsGenericVat: true,
        contraAccount: true,
        verificationSeries: true,
        countries: [Country.SE],
        hasAccountDefaultTaxCode: false,
        supportedFeatures: {
            [ExportFeature.REPRESENTATION]: [
                {
                    name: RepresentationFeatureProperty.DEDUCTIBLE_COST_ACCOUNT,
                    required: true,
                    countries: [Country.SE],
                },
                {
                    name: RepresentationFeatureProperty.NON_DEDUCTIBLE_ACCOUNT,
                    required: true,
                    countries: [Country.SE],
                },
            ],
            [ExportFeature.REVERSE]: [
                {
                    name: ReverseFeatureProperty.DEFAULT_INGOING_TAX_ACCOUNT,
                    required: true,
                    countries: [Country.SE, Country.NO],
                },
            ],
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
            ],
        },
    },
    sie_marathon: {
        value: 'sie_marathon',
        name: SystemName.SIE_MARATHON,
        csv: true,
        direct: false,
        custom: true,
        hidden: false,
        reverse: true,
        supportsGenericVat: true,
        contraAccount: true,
        verificationSeries: true,
        countries: [Country.SE],
        hasAccountDefaultTaxCode: false,
        supportedFeatures: {
            [ExportFeature.REPRESENTATION]: [
                {
                    name: RepresentationFeatureProperty.DEDUCTIBLE_COST_ACCOUNT,
                    required: true,
                    countries: [Country.SE],
                },
                {
                    name: RepresentationFeatureProperty.NON_DEDUCTIBLE_ACCOUNT,
                    required: true,
                    countries: [Country.SE],
                },
            ],
            [ExportFeature.REVERSE]: [
                {
                    name: ReverseFeatureProperty.DEFAULT_INGOING_TAX_ACCOUNT,
                    required: true,
                    countries: [Country.SE],
                },
            ],
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: true},
            ],
        },
    },
    inventio: {
        value: 'inventio',
        name: SystemName.INVENTIO,
        csv: false,
        reverse: false,
        direct: true,
        apiKeyIntegration: true,
        apiKeySystemContextName: t`Ref ID`,
        hasAccountDefaultTaxCode: true,
        hasExternalAccounts: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
        },
        apiKeyIntegrationHelpFooter: (
            <Trans>
                In doubt of where to find these?{' '}
                <Link
                    href={getHelpCentreArticleLinkIntercom(
                        '4909823-setting-up-the-inventio-integration',
                    )}
                >
                    Learn more
                </Link>
            </Trans>
        ),
    },
    economic: {
        value: 'economic',
        name: SystemName.ECONOMIC,
        cashbook: true,
        contraAccount: true,
        employeeContraAccount: true,
        reverse: false,
        direct: true,
        csv: true,
        hasAccountDefaultTaxCode: true,
        hasCashbookAccounts: true,
        hasExternalAccounts: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.EMPLOYEE_CASHBOOK, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
            [ExportFeature.BILL_INVOICE]: [
                {name: BillInvoiceFeatureProperty.FULL_LIST_OF_ACCOUNTS, required: true},
            ],
        },
        supportsTags: true,
        tagsPromptDescription: t`Departments, Cost Types and Projects`,
        helpCentre: {
            folderId: 103000620248,
            supportedLanguages: [SupportedLanguage.DA],
        },
    },
    xero: {
        value: 'xero',
        name: SystemName.XERO,
        csv: false,
        reverse: false,
        direct: true,
        employeeContraAccount: true,
        hasAccountDefaultTaxCode: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
            ],
            [ExportFeature.BILL_INVOICE]: [
                {name: BillInvoiceFeatureProperty.FULL_LIST_OF_ACCOUNTS, required: true},
                {name: BillInvoiceFeatureProperty.SUPPLIER, required: true},
                {name: BillInvoiceFeatureProperty.SHOW_EXPORT_IN_BILLS_DASHBOARD, required: true},
                {name: BillInvoiceFeatureProperty.EXPORT_CAN_SYNC, required: true},
            ],
        },
        supportsTags: true,
        tagsPromptDescription: t`Tracking Categories and Customers`,
        helpCentre: {
            folderId: 103000620249,
        },
    },
    billy: {
        value: 'billy',
        name: SystemName.BILLY,
        contraAccount: true,
        reverse: false,
        csv: true,
        supportsGenericVat: true,
        hasAccountDefaultTaxCode: false,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
        },
    },
    billy_direct: {
        value: 'billy_direct',
        name: SystemName.BILLY_DIRECT,
        contraAccount: true,
        reverse: false,
        direct: true,
        csv: false,
        hasAccountDefaultTaxCode: true,
        hasExternalAccountId: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
        },
        directSystemValue: 'billy',
        apiKeyIntegration: true,
        apiKeySystemContextName: t`OAuth Access Token`,
        apiKeyIntegrationHelpInstructions: BILLY_INSTRUCTIONS,
    },
    dinero: {
        value: 'dinero',
        name: SystemName.DINERO,
        contraAccount: true,
        csv: true,
        reverse: false,
        supportsGenericVat: true,
        hasAccountDefaultTaxCode: false,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
        },
    },
    dinero_direct: {
        value: 'dinero_direct',
        name: SystemName.DINERO_DIRECT,
        contraAccount: true,
        csv: false,
        direct: true,
        reverse: false,
        supportsGenericVat: false,
        hasAccountDefaultTaxCode: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
        },
        directSystemValue: 'dinero',
    },
    navision: {
        value: 'navision',
        name: SystemName.NAVISION,
        csv: true,
        reverse: false,
        contraAccount: true,
        hasAccountDefaultTaxCode: false,
        doesNotSupportTaxCodes: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
        },
    },
    netvisor: {
        value: 'netvisor',
        name: SystemName.NETVISOR,
        csv: true,
        direct: false,
        custom: true,
        hidden: false,
        reverseWithCode: true,
        noReverse: true,
        supportsGenericVat: true,
        contraAccount: true,
        verificationSeries: false,
        countries: [Country.SE, Country.FI],
        hasAccountDefaultTaxCode: false,
        supportedFeatures: {
            [ExportFeature.REVERSE]: [
                {
                    name: ReverseFeatureProperty.DEFAULT_INGOING_TAX_ACCOUNT,
                    required: true,
                    countries: [Country.SE, Country.FI],
                },
            ],
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
            ],
        },
    },
    uniconta_legacy: {
        value: 'uniconta_legacy',
        name: SystemName.UNICONTA_LEGACY,
        contraAccount: true,
        reverse: false,
        csv: true,
        hasAccountDefaultTaxCode: false,
        doesNotSupportTaxCodes: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
        },
    },
    quickbooks: {
        value: 'quickbooks',
        name: SystemName.QUICKBOOKS,
        csv: true,
        reverse: false,
        hasAccountDefaultTaxCode: false,
        doesNotSupportTaxCodes: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
        },
    },
    quickbooks_direct: {
        value: 'quickbooks_direct',
        name: SystemName.QUICKBOOKS_DIRECT,
        contraAccount: true,
        employeeContraAccount: true,
        csv: false,
        direct: true,
        reverse: false,
        supportsGenericVat: true,
        hasAccountDefaultTaxCode: false,
        hasExternalAccountId: true,
        hasExternalAccounts: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
            [ExportFeature.BILL_INVOICE]: [
                {name: BillInvoiceFeatureProperty.FULL_LIST_OF_ACCOUNTS, required: true},
                {name: BillInvoiceFeatureProperty.SHOW_EXPORT_IN_BILLS_DASHBOARD, required: true},
                {name: BillInvoiceFeatureProperty.SUPPLIER, required: true},
            ],
        },
        directSystemValue: 'quickbooks',
        supportsTags: true,
        tagsPromptDescription: t`Classes and Projects`,
        helpCentre: {
            folderId: 103000620250,
        },
    },
    sageOne: {
        value: 'sageOne',
        name: SystemName.SAGE_ONE,
        csv: true,
        reverse: false,
        hasAccountDefaultTaxCode: false,
        doesNotSupportTaxCodes: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
        },
    },
    sage50: {
        value: 'sage50',
        name: SystemName.SAGE_FIFTY,
        contraAccount: true,
        csv: true,
        reverse: false,
        supportsGenericVat: true,
        noReverse: true,
        hasAccountDefaultTaxCode: false,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
        },
    },
    sage200: {
        value: 'sage200',
        name: SystemName.SAGE_TWO_HUNDRED,
        contraAccount: true,
        csv: true,
        reverse: false,
        supportsGenericVat: true,
        hasAccountDefaultTaxCode: false,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
        },
    },
    freeagent: {
        value: 'freeagent',
        name: SystemName.FREEAGENT,
        csv: true,
        reverse: false,
        hasAccountDefaultTaxCode: false,
        doesNotSupportTaxCodes: true,
    },
    timelog: {
        value: 'timelog',
        name: SystemName.TIMELOG,
        csv: true,
        hidden: true,
        reverse: false,
        hasAccountDefaultTaxCode: false,
    },
    visma: {
        value: 'visma',
        name: SystemName.VISMA,
        csv: false,
        direct: true,
        cashbook: true,
        reverse: true,
        contraAccount: true,
        employeeContraAccount: true,
        supportsGenericVat: true,
        countries: [Country.SE],
        hasExternalAccounts: true,
        hasCashbookAccounts: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
            ],
            [ExportFeature.REPRESENTATION]: [
                {
                    name: RepresentationFeatureProperty.DEDUCTIBLE_COST_ACCOUNT,
                    required: true,
                    countries: [Country.SE],
                },
                {
                    name: RepresentationFeatureProperty.NON_DEDUCTIBLE_ACCOUNT,
                    required: true,
                    countries: [Country.SE],
                },
            ],
            [ExportFeature.REVERSE]: [
                {
                    name: ReverseFeatureProperty.DEFAULT_INGOING_TAX_ACCOUNT,
                    required: true,
                    countries: [Country.SE],
                },
            ],
            [ExportFeature.BILL_INVOICE]: [
                {name: BillInvoiceFeatureProperty.FULL_LIST_OF_ACCOUNTS, required: true},
            ],
        },
        supportsTags: true,
        tagsPromptDescription: t`Projects and Cost Centers`,
    },
    vismadotnet: {
        value: 'vismadotnet',
        name: SystemName.VISMADOTNET,
        csv: false,
        direct: true,
        cashbook: true,
        reverse: true,
        contraAccount: true,
        employeeContraAccount: true,
        supportsGenericVat: true,
        hasExternalAccounts: true,
        hasCashbookAccounts: true,
        countries: [Country.SE],
        featureFlagged: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
            [ExportFeature.REVERSE]: [
                {
                    name: ReverseFeatureProperty.DEFAULT_INGOING_TAX_ACCOUNT,
                    required: true,
                    countries: [Country.SE],
                },
            ],
        },
    },
    lexoffice: {
        value: 'lexoffice',
        name: SystemName.LEXOFFICE,
        csv: false,
        direct: true,
        reverse: true,
        supportsGenericVat: true,
        hasAccountDefaultTaxCode: false,
        countries: [Country.DE],
        hasExternalAccountId: true,
    },
    businesscentral: {
        value: 'businesscentral',
        name: SystemName.BUSINESSCENTRAL,
        csv: false,
        direct: true,
        reverse: false,
        contraAccount: true,
        supportsGenericVat: true,
        hasAccountDefaultTaxCode: false,
        hasExternalAccounts: true,
        hasCashbookAccounts: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CASHBOOK, required: true},
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: true},
            ],
        },
        supportsTags: true,
        tagsPromptDescription: t`Dimensions`,
    },
    bmd: {
        value: 'bmd',
        name: SystemName.BMD,
        csv: true,
        reverse: false,
        contraAccount: true,
        employeeContraAccount: true,
        supportsGenericVat: true,
        countries: [Country.DE, Country.AT],
        hasAccountDefaultTaxCode: false,
        noReverse: true,
        normalTaxTableWithType: true,
        costCenter1TagGroup: {
            title: () => t`Tag group for cost center`,
            description: () => t`Tag group value that will be exported into cost center`,
            helpArticleUrl: getHelpCentreArticleLinkIntercom(8247232, 'de'),
            codeColumn: true,
            nameColumn: false,
        },
        costCenter2TagGroup: {
            title: () => t`Tag group for branch number`,
            description: () => t`Tag group value that will be exported into branch number`,
            helpArticleUrl: getHelpCentreArticleLinkIntercom(8247232, 'de'),
            codeColumn: true,
            nameColumn: false,
        },
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: true},
            ],
        },
        booksymbol: true,
    },
    procountor: {
        value: 'procountor',
        name: SystemName.PROCOUNTOR,
        csv: false,
        direct: true,
        cashbook: false,
        reverse: true,
        contraAccount: true,
        employeeContraAccount: true,
        supportsGenericVat: true,
        hasExternalAccounts: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: true},
            ],
        },
        supportsTags: true,
        tagsPromptDescription: t`Dimensions`,
    },
    exact: {
        value: 'exact',
        name: SystemName.EXACT,
        csv: false,
        direct: true,
        cashbook: true,
        hasExternalAccountId: true,
        reverse: true,
        contraAccount: true,
        // TODO: Uncomment after enabling Pocket feature
        // employeeContraAccount: true,
        supportsGenericVat: true,
        hasExternalAccounts: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
        },
        verificationSeries: true,
        supportsTags: true,
        tagsPromptDescription: t`Cost Centres and Cost Units`,
    },
    cegidloop: {
        value: 'cegidloop',
        name: SystemName.CEGIDLOOP,
        contraAccount: true,
        employeeContraAccount: true,
        cashbook: true,
        employeeCashbook: true,
        reverse: true,
        direct: true,
        csv: false,
        hasAccountDefaultTaxCode: true,
        hasExternalAccounts: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: true},
            ],
            [ExportFeature.REVERSE]: [
                {name: ReverseFeatureProperty.DEFAULT_INGOING_TAX_ACCOUNT, required: true},
            ],
        },
        apiKeyIntegration: true,
        apiKeySystemContextName: t`API Key. Format should be: [Key]:[Secret] e.g. 2add345:avhsh9kjjd`,
        apiKeyIntegrationHelpInstructions: CEGID_LOOP_INSTRUCTIONS,
    },
    netsuite: {
        value: 'netsuite',
        name: SystemName.NETSUITE,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
        },
        externalConfig: {
            oauthUrl:
                'https://system.netsuite.com/suiteapp/ui/marketplace.nl?sc=-180&whence=#/app?id=com.pleo.pleo',
            configUrl: 'https://system.netsuite.com/app/center/card.nl?sc=-7&whence=',
        },
        helpCentre: {
            articleId: 8662142,
        },
    },
    twinfield: {
        value: 'twinfield',
        name: SystemName.TWINFIELD,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
            [ExportFeature.BILL_INVOICE]: [
                {name: BillInvoiceFeatureProperty.SHOW_EXPORT_IN_BILLS_DASHBOARD, required: true},
            ],
        },
        helpCentre: {
            articleId: 8890507,
            supportedLanguages: [SupportedLanguage.EN, SupportedLanguage.NL],
        },
    },
    sage_active: {
        value: 'sage_active',
        name: SystemName.SAGE_ACTIVE,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
            [ExportFeature.BILL_INVOICE]: [
                {name: BillInvoiceFeatureProperty.SHOW_EXPORT_IN_BILLS_DASHBOARD, required: true},
            ],
        },
        externalConfig: {
            oauthUrl: 'https://staging.pleo-sage.wecode.dev/pleo/installation',
            configUrl: 'https://staging.pleo-sage.wecode.dev/integration-configure',
        },
    },
    xledger: {
        value: 'xledger',
        name: SystemName.XLEDGER,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
        },
        externalConfig: {
            oauthUrl: 'https://xledger-h.cyclr.uk',
            configUrl: 'https://9903-91-110-162-93.ngrok-free.app/connector/callback',
        },
    },
    dynamics_365_finance: {
        value: 'dynamics_365_finance',
        name: SystemName.DYNAMICS_365_FINANCE,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
        },
        externalConfig: {
            oauthUrl: 'https://sprint365-uat.sandbox.operations.eu.dynamics.com',
            configUrl: 'https://sprint365-uat.sandbox.operations.eu.dynamics.com',
        },
    },
    sap_business_one: {
        value: 'sap_business_one',
        name: SystemName.SAP_BUSINESS_ONE,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
            [ExportFeature.BILL_INVOICE]: [],
        },
    },
    sap_s4hana: {
        value: 'sap_s4hana',
        name: SystemName.SAP_S4HANA,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.BILL_INVOICE]: [],
            [ExportFeature.POCKET]: [],
        },
        externalConfig: {
            configUrl: 'https://emea.cockpit.btp.cloud.sap/cockpit',
        },
    },
    odoo: {
        value: 'odoo',
        name: SystemName.ODOO,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
            [ExportFeature.BILL_INVOICE]: [
                {name: BillInvoiceFeatureProperty.SHOW_EXPORT_IN_BILLS_DASHBOARD, required: true},
            ],
        },
    },
    uniconta: {
        value: 'uniconta',
        name: SystemName.UNICONTA,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
            [ExportFeature.BILL_INVOICE]: [
                {name: BillInvoiceFeatureProperty.SHOW_EXPORT_IN_BILLS_DASHBOARD, required: true},
            ],
        },
        externalConfig: {
            oauthUrl: 'https://pleo.dev.flows.systems/uniconta/activation',
            configUrl: 'https://pleo.dev.flows.systems/uniconta/oauth/cb',
        },
    },
    elo_digital_office: {
        value: 'elo_digital_office',
        name: SystemName.ELO_DIGITAL_OFFICE,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
            [ExportFeature.BILL_INVOICE]: [],
        },
        externalConfig: {
            oauthUrl: 'https://www.beyond-paper.de/',
            configUrl: 'https://www.beyond-paper.de/',
        },
    },
    sage_100_france: {
        value: 'sage_100_france',
        name: SystemName.SAGE_100_FRANCE,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
            [ExportFeature.BILL_INVOICE]: [],
        },
    },
    sage_intacct: {
        value: 'sage_intacct',
        name: SystemName.SAGE_INTACCT,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
            [ExportFeature.BILL_INVOICE]: [],
        },
    },
    'visma-economic': {
        value: 'visma-economic',
        name: SystemName.VISMA_ECONOMIC,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
            [ExportFeature.BILL_INVOICE]: [],
        },
    },
    acd: {
        value: 'acd',
        name: SystemName.ACD,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
            [ExportFeature.BILL_INVOICE]: [],
        },
    },
    pennylane: {
        value: 'pennylane',
        name: SystemName.PENNYLANE,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
            [ExportFeature.BILL_INVOICE]: [],
        },
    },
    myunisoft: {
        value: 'myunisoft',
        name: SystemName.MYUNISOFT,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
            [ExportFeature.BILL_INVOICE]: [],
        },
    },
    businesscentral_new: {
        value: 'businesscentral_new',
        name: SystemName.BUSINESSCENTRAL_NEW,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
            [ExportFeature.BILL_INVOICE]: [],
        },
        supportsTags: true,
        tagsPromptDescription: t`Dimensions`,
    },
    generic: {
        value: 'generic',
        name: t`Generic format`,
        csv: true,
        hidden: true,
        reverse: false,
        supportsGenericVat: true,
        contraAccount: true,
        employeeContraAccount: true,
        hasAccountDefaultTaxCode: false,
        normalTaxTableWithType: [Country.SE],
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
            [ExportFeature.REPRESENTATION]: [
                {
                    name: RepresentationFeatureProperty.DEDUCTIBLE_COST_ACCOUNT,
                    required: true,
                    countries: [Country.SE],
                },
                {
                    name: RepresentationFeatureProperty.NON_DEDUCTIBLE_ACCOUNT,
                    required: true,
                    countries: [Country.SE],
                },
            ],
            [ExportFeature.REVERSE]: [
                {
                    name: ReverseFeatureProperty.DEFAULT_INGOING_TAX_ACCOUNT,
                    required: true,
                    countries: [Country.SE],
                },
            ],
        },
    },
    custom: {
        value: 'custom',
        name: t`Custom format`,
        csv: true,
        hidden: true,
        noReverse: true,
        contraAccount: true,
        supportsGenericVat: true,
        employeeContraAccount: true,
        normalTaxTableWithType: [Country.SE],
        supportedFeatures: {
            [ExportFeature.POCKET]: [
                {name: PocketFeatureProperty.EMPLOYEE_CONTRA_ACCOUNT, required: true},
                {name: PocketFeatureProperty.TRANSITION_ACCOUNT, required: false},
            ],
            [ExportFeature.REPRESENTATION]: [
                {
                    name: RepresentationFeatureProperty.DEDUCTIBLE_COST_ACCOUNT,
                    required: true,
                    countries: [Country.SE],
                },
                {
                    name: RepresentationFeatureProperty.NON_DEDUCTIBLE_ACCOUNT,
                    required: true,
                    countries: [Country.SE],
                },
            ],
        },
    },
    test_integration: {
        value: 'test_integration',
        name: SystemName.TEST_INTEGRATION,
        subtitle: t`Partner Integration for Testing Purposes`,
        direct: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
        },
        externalConfig: {
            configUrl: '', // It will be added once the integration is ready
        },
    },
    custom_api_integration: {
        value: 'custom_api_integration',
        name: SystemName.CUSTOM_API_INTEGRATION,
        subtitle: t`For customer built integration with the Pleo API`,
        direct: true,
        hidden: true,
        isExternalApi: true,
        isExportDispatcherIntegration: true,
        normalTaxTableWithType: true,
        supportsGenericVat: true,
        supportedFeatures: {
            [ExportFeature.POCKET]: [],
        },
        externalConfig: {
            configUrl: '', // It will be added once the integration is ready
        },
    },
})

const BILLY_STEPS = {
    ACCESS_KEY: 'Adgangsnøgler',
    CREATE_ACCESS_KEY: 'Opret adgangsnøgle',
    KEY: 'Nøgle',
}

const BILLY_INSTRUCTIONS = [
    <Trans>
        Login to{' '}
        <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://mit.billy.dk/"
            data-testid={'settings_accounting_setup-direct_billy-link'}
        >
            Billy
        </Link>
    </Trans>,
    <Trans>Open settings</Trans>,
    <Trans>
        Click on <strong>{BILLY_STEPS.ACCESS_KEY}</strong>
    </Trans>,
    <Trans>
        Click on <strong>{BILLY_STEPS.CREATE_ACCESS_KEY}</strong>
    </Trans>,
    <Trans>Type a name like "Pleo" and press "Opret"</Trans>,
    <Trans>
        Copy your <strong>{BILLY_STEPS.KEY}</strong> from Billy and paste it below
    </Trans>,
]

const CEGID_LOOP_INSTRUCTIONS = [
    <Trans>
        Login to{' '}
        <Link target="_blank" rel="noopener noreferrer" href="https://www.cegidlife.com/">
            Cegid Life
        </Link>
    </Trans>,
    <Trans>From the main CegidLife screen, choose your profile on the right.</Trans>,
    <Trans>
        Click on the button to view the service catalog (<i lang="fr">"Catalogue de services"</i>).
    </Trans>,
    <Trans>
        Select the option from the <strong lang="fr">"Services en lignes"</strong> drop-down list
        and click on the <strong>"Filter"</strong> button. The service name is{' '}
        <strong>"Loop APIkey"</strong>.
    </Trans>,
    <Trans>Click on the key-shaped button corresponding to the service</Trans>,
    <Trans>
        Give a name to the API key and click on <strong lang="fr">Générer</strong>
    </Trans>,
    <Trans>
        You get two parts of the API key: a key (<strong lang="fr">"identifiant"</strong>) and a
        client secret (<strong lang="fr">"secret"</strong>).
    </Trans>,
    <Trans>
        See{' '}
        <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://assistanceloop.blob.core.windows.net/documentation/API%20Publiques/APIS_PubliquesApiKey.pdf"
        >
            this document
        </Link>{' '}
        for more visual instructions.
    </Trans>,
]

const HOLDED_DIRECT_INSTRUCTIONS = [
    <Trans>
        Login to{' '}
        <Link
            target="_blank"
            rel="noopener noreferrer"
            href="https://app.holded.com/api"
            data-testid={'settings_accounting_setup-direct_holded-link'}
        >
            Holded
        </Link>
    </Trans>,
    <Trans>Click on your profile in the top right corner</Trans>,
    <Trans>Click settings</Trans>,
    <Trans>Under More, click Developers</Trans>,
    <Trans>
        Click on <strong>New Api Key</strong>
    </Trans>,
    <Trans>Type a name (e.g., Pleo) and click Save</Trans>,
    <Trans>
        Copy your <strong>APIKEY</strong> from Holded and paste it below
    </Trans>,
]

export function getAccountingSystemsOptions(supportedExportDispatcherIntegrations: string[]) {
    const systems = generateSystems()
    const isUnsupportedExportDispatcherIntegration = (system: AccountingSystemDetails) => {
        return (
            system.isExportDispatcherIntegration &&
            !supportedExportDispatcherIntegrations.includes(system.value)
        )
    }

    return Object.values(systems)
        .filter((system) => !system.hidden && !isUnsupportedExportDispatcherIntegration(system))
        .map((system) => ({
            value: system.value,
            label: system.name,
            subtitle: system.subtitle,
            data: system,
        }))
}
