import {useMemo} from 'react'
import {useGate} from 'statsig-react'

import {PlanType} from '@product-web/api-types/plan'
import {dayjs} from '@product-web/dates/dayjs'
import {useCompanySubscription} from '@product-web/paywall/lib/company-subscription'
import {useUser} from '@product-web/user'
import {useVendorCard} from '@product-web-features/vendor-cards/lib/use-vendor-card'

import {bffHooks} from '../../bff-hooks'

/**
 * @returns {boolean} - Whether the Spend Guide Activation Guide should be visible to the Admin
 *
 * @return {true} if all of the following conditions are met:
 * - if customer is on Advanced plan
 * - if customer has recurring spend on personal cards
 * - if customer is older than 60 days
 * - if customer has no sub-wallets
 * - if feature-gate is enabled
 */

export const useSpendActivationGuide = () => {
    const {value: featureGateEnabled} = useGate('spend_activation_setup_flow')

    const user = useUser()
    const {data: subscription} = useCompanySubscription()

    const subscriptionPlan = subscription?.platformRatePlan.type

    const companyOnAdvancedPlan = subscriptionPlan === PlanType.ADVANCED

    const companyOlderThan60Days =
        !!user?.company?.createdAt && dayjs().diff(dayjs(user?.company?.createdAt), 'days') > 60

    const {canUseVendorCards} = useVendorCard()

    const today = dayjs().startOf('day').format('YYYY-MM-DD')
    const oneYearBefore = dayjs().subtract(1, 'year').format('YYYY-MM-DD')

    const shouldFetchData = featureGateEnabled && canUseVendorCards && companyOnAdvancedPlan

    const {data: {totalVendorCards = 0, totalRecurringVendors = 0} = {}} =
        bffHooks.recurringVendors.recurringVendorsList.getRecurringVendorsSummaryForPersonalCard.useQuery(
            {from: [oneYearBefore], to: [today]},
            {enabled: shouldFetchData},
        )

    const {data: companyHasSubWallet} =
        bffHooks.recurringVendors.recurringVendorsList.getCompanyHasExistingSubWallet.useQuery()

    const shouldShowSpendGuideActivationGuide =
        featureGateEnabled &&
        canUseVendorCards &&
        companyOnAdvancedPlan &&
        companyOlderThan60Days &&
        !companyHasSubWallet &&
        totalRecurringVendors > 0

    return useMemo(
        () => ({
            isEnabled: shouldShowSpendGuideActivationGuide,
            totalRecurringVendors,
            totalVendorCards,
        }),
        [shouldShowSpendGuideActivationGuide, totalRecurringVendors, totalVendorCards],
    )
}
