import {t, Trans} from '@lingui/macro'
import styled from 'styled-components'

import {Box, Inline, Stack, Text, tokens} from '@pleo-io/telescope'

import {formatCurrency} from '@product-web/locale/currency'
import {formatNumber} from '@product-web/locale/helpers'

import type {CalculatePricingOutput} from '../../index.bff'
import {partnerTierNamesMap} from '../../lib/partner-tier'

interface Props {
    isDirty: boolean
    pricingQuote: CalculatePricingOutput
}
export const PricingResults = ({isDirty, pricingQuote}: Props) => {
    const {plan, additionalUsers, cashback, contractValue, currencyCode} = pricingQuote
    const planUserCount = plan.userCount
    const additionalUserCount = additionalUsers?.userCount
    const discount = plan.discount
    const isMonthly = pricingQuote.billingFrequency === 'MONTHLY'
    const partnerTier = partnerTierNamesMap()[pricingQuote.partnerTier]
    const cashbackPercentage = cashback?.percentage || 0
    const textColor = isDirty ? 'colorContentInteractiveDisabled' : 'colorContentInteractive'
    const textColorQuiet = isDirty ? 'colorContentInteractiveDisabled' : 'colorContentStaticQuiet'

    const titleRow = [
        t`Type`,
        t`Details`,
        t`Quantity`,
        t`Price (${currencyCode})`,
        t`Total (${currencyCode})`,
    ]
    const planRow = [
        plan.type,
        plan.total > 0 ? t`${planUserCount} users included` : t`${planUserCount} free users`,
        plan.quantity,
        formatNumber(plan.price, {minimumFractionDigits: 2}),
        formatNumber(plan.total, {minimumFractionDigits: 2}),
    ]
    const additionalUsersRow = additionalUsers
        ? [
              t`Additional users`,
              t`${additionalUserCount} users`,
              additionalUsers.quantity,
              formatNumber(additionalUsers.price, {minimumFractionDigits: 2}),
              formatNumber(additionalUsers.total, {minimumFractionDigits: 2}),
          ]
        : null

    const cashbackRow = cashback
        ? [
              t`Cashback` + ' *',
              t`${cashbackPercentage}% rebate`,
              '',
              '',
              formatNumber(-cashback.annual, {minimumFractionDigits: 2}),
          ]
        : null

    return (
        <div role="grid">
            <GridRow role="row">
                {titleRow.map((cell, index) => (
                    <Text
                        key={`title-row-${index}`}
                        role="columnheader"
                        color={textColor}
                        weight="semibold"
                    >
                        {cell + (discount > 0 && index > 2 ? ' *' : '')}
                    </Text>
                ))}
            </GridRow>
            <GridRow role="row">
                {planRow.map((cell, index) => (
                    <Text key={`plan-row-${index}`} role="gridcell" color={textColorQuiet}>
                        {cell}
                    </Text>
                ))}
            </GridRow>
            {additionalUsersRow && (
                <GridRow role="row">
                    {additionalUsersRow.map((cell, index) => (
                        <Text
                            key={`additional-users-row-${index}`}
                            role="gridcell"
                            color={textColorQuiet}
                        >
                            {cell}
                        </Text>
                    ))}
                </GridRow>
            )}
            {cashbackRow && (
                <GridRow role="row">
                    {cashbackRow.map((cell, index) => (
                        <Text key={`cashback-row-${index}`} role="gridcell" color={textColorQuiet}>
                            {cell}
                        </Text>
                    ))}
                </GridRow>
            )}
            <Box pb={28} />
            <Inline role="row" justifyContent="space-between" mb={12}>
                <Text role="gridcell" color={textColor} weight="semibold">
                    {isMonthly ? (
                        <Trans>Total per month {currencyCode}</Trans>
                    ) : (
                        <Trans>Total per year {currencyCode}</Trans>
                    )}
                    {discount > 0 && ' *'}
                </Text>
                <Text role="gridcell" color={textColor} weight="semibold">
                    {formatNumber(isMonthly ? contractValue.monthly : contractValue.annual, {
                        minimumFractionDigits: 2,
                    })}
                </Text>
            </Inline>
            {discount > 0 && (
                <Text variant="small-subtle" color={textColorQuiet}>
                    <Trans>
                        * The prices shown in this table already includes your {discount}% discount
                        that you will receive as a {partnerTier} Partner.
                    </Trans>
                </Text>
            )}
            {cashbackRow && (
                <Text variant="small-subtle" color={textColorQuiet}>
                    <Trans>
                        * Annual cashback rebate on card spend, credited to your client's Pleo
                        wallet monthly. Cashback cannot exceed Pleo subscription fees.
                    </Trans>
                </Text>
            )}
            <Inline mt={48} space={24} alignItems="center">
                <ViewDetails data-testid="contract-value">
                    <Text as="h4" variant="large-accent" color={textColor}>
                        <Trans>Contract value</Trans>
                    </Text>
                    <Inline mt={8} space={8}>
                        <Text color={textColor}>
                            <Trans>Monthly:</Trans>
                        </Text>
                        <Text color={textColorQuiet}>
                            {formatCurrency(contractValue.monthly, pricingQuote.currencyCode)}
                        </Text>
                    </Inline>
                    <Inline space={8}>
                        <Text color={textColor}>
                            <Trans>Annually:</Trans>
                        </Text>
                        <Text color={textColorQuiet}>
                            {formatCurrency(contractValue.annual, pricingQuote.currencyCode)}
                        </Text>
                    </Inline>
                </ViewDetails>
                <ViewDetails data-testid="price-per-user">
                    <Text as="h4" variant="large-accent" color={textColor}>
                        <Trans>Price per user</Trans>
                    </Text>
                    <Inline mt={8} space={8}>
                        <Text color={textColor}>
                            <Trans>Monthly:</Trans>
                        </Text>
                        <Text color={textColorQuiet}>
                            {formatCurrency(
                                contractValue.monthlyPerUser,
                                pricingQuote.currencyCode,
                            )}
                        </Text>
                    </Inline>
                    <Inline space={8}>
                        <Text color={textColor}>
                            <Trans>Annually:</Trans>
                        </Text>
                        <Text color={textColorQuiet}>
                            {formatCurrency(contractValue.annualPerUser, pricingQuote.currencyCode)}
                        </Text>
                    </Inline>
                </ViewDetails>
            </Inline>
        </div>
    )
}

export const GridRow = styled(Box).attrs({mb: 12})`
    display: grid;
    grid-template-columns: 25% 30% 15% 15% 15%;
    width: 100%;

    & > :nth-child(3) {
        text-align: center;
    }

    & > :nth-child(4),
    & > :nth-child(5) {
        text-align: right;
    }
`

export const ViewDetails = styled(Stack).attrs({align: 'center', p: 24, space: 4})`
    border: ${tokens.borderPrimary};
    border-radius: ${tokens.arc8};
`
